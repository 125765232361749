<template>
  <div>
    <h1 class="text-white">TERMS AND CONDITIONS</h1>

    <em class="text-white">Last updated: April 21, 2023</em>

    <p class="text-white">
      Welcome to MeetLouis, an LMS platform provided by StopLeads OÜ ("StopLeads," "we," "us," "our") company located in Sepapaja
      6, Tallinn, Estonia and registered under the number 14475103. These terms of service ("Terms") govern your use of the
      MeetLouis platform and services, which include hosting, appbuilder, pagebuilder, and CRM (collectively, the "Services"). By
      using the Services, you agree to these Terms, so please read them carefully.
    </p>

    <h2 class="text-white">Use of the Services</h2>
    <p class="text-white">
      1.1 Eligibility: You must be at least 18 years of age or the age of legal majority in your jurisdiction to use the Services.
      You must also be authorized to use the Services on behalf of any organization or entity that you represent.
    </p>

    <p class="text-white">
      1.2 Account Creation: To use the Services, you must create an account. You agree to provide accurate and complete
      information when creating your account and to keep your account information up to date. You are responsible for maintaining
      the confidentiality of your account credentials and for all activities that occur under your account.
    </p>

    <p class="text-white">
      1.3 Use of the Services: You may use the Services only for lawful purposes and in accordance with these Terms. You may not
      use the Services to:
    </p>

    <ul>
      <li class="text-white">(a) violate any applicable law or regulation;</li>

      <li class="text-white">(b) infringe any intellectual property or other proprietary rights;</li>

      <li class="text-white">(c) transmit any viruses, worms, or other malicious software;</li>

      <li class="text-white">(d) engage in any fraudulent, deceptive, or misleading conduct;</li>

      <li class="text-white">(e) harass, intimidate, or threaten any person;</li>

      <li class="text-white">(f) interfere with or disrupt the Services or servers or networks connected to the Services; or</li>

      <li class="text-white">
        (g) engage in any other activity that we deem, in our sole discretion, to be harmful to the Services, our users, or any
        third party.
      </li>
    </ul>

    <p class="text-white">
      1.4 Content: You are solely responsible for all content that you upload, publish, display, or otherwise make available
      through the Services ("Content"). You represent and warrant that you have all necessary rights to use and distribute the
      Content, and that the Content does not infringe any intellectual property or other proprietary rights. You grant us a
      worldwide, non-exclusive, transferable, royalty-free, sublicensable license to use, copy, modify, distribute, and display
      the Content in connection with the provision of the Services.
    </p>

    <p class="text-white">
      1.5 Acceptable Use Policy: You must comply with our acceptable use policy ("AUP"), which is incorporated into these Terms by
      reference. The AUP sets forth certain prohibited uses of the Services, and we may suspend or terminate your account if you
      violate the AUP.
    </p>

    <p class="text-white">
      1.6 Generated content: Users retain full ownership of the content they create using our services. We do not claim any
      ownership rights over the user-generated content.
    </p>

    <p class="text-white">
      1.7 User Responsibility: Users are solely responsible for the content they write and publish using our services. It is
      important to ensure that the content is accurate, lawful, and complies with legal and ethical standards. Users should
      exercise caution and adhere to copyright laws when incorporating external materials into their content.
    </p>

    <p class="text-white">
      1.8 AI-generated Content Disclaimer: While we strive to provide reliable and accurate results, it is important to
      acknowledge that AI-generated content may occasionally contain errors, inaccuracies, or fictional elements. We cannot be
      held responsible for any misleading, false, or hallucinatory content produced by the AI. Users are advised to review and
      verify the content generated by AI before publishing or sharing it with others.
    </p>

    <h2 class="text-white">Payment and Fees</h2>
    <p class="text-white">
      2.1 Fees: We may charge fees for certain Services, and we will provide notice of such fees before you incur them. You agree
      to pay all fees when due and to provide us with a valid payment method.
    </p>

    <p class="text-white">
      2.2 Changes to Fees: We may change our fees at any time, but we will provide you with notice of any changes before they take
      effect. If you do not agree to the new fees, you may cancel your account before the new fees take effect.
    </p>

    <p class="text-white">2.3 Refunds: We do not provide refunds for any fees that you have paid, except as required by law.</p>

    <h2 class="text-white">Intellectual Property</h2>
    <p class="text-white">
      3.1 Ownership: We own all rights, title, and interest in and to the Services, including all intellectual property rights.
      You acknowledge that you do not acquire any ownership rights by using the Services.
    </p>

    <p class="text-white">
      3.2 Trademarks: MeetLouis and StopLeads are trademarks of StopLeads OÜ. You may not use our trademarks without our prior
      written permission.
    </p>

    <p class="text-white">
      3.3 Feedback: If you provide us with any suggestions, ideas, or feedback regarding the Services ("Feedback"), you grant us a
      worldwide, non-exclusive, transferable, sublicensable, royalty-free license to use and exploit the Feedback in any way,
      without any obligation to compensate you.
    </p>

    <p class="text-white">
      3.4 Third-Party Content: The Services may contain links to third-party websites, services, or content that are not owned or
      controlled by us. We do not endorse or assume any responsibility for any such third-party sites, information, materials,
      products, or services. If you access any third-party website, service, or content from the Services, you do so at your own
      risk, and you agree that we will have no liability arising from your use of or access to any such third-party website,
      service, or content.
    </p>

    <h2 class="text-white">Confidentiality</h2>
    <p class="text-white">
      4.1 Definition: "Confidential Information" means any non-public information disclosed by us to you, whether orally or in
      writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of
      the information and the circumstances of disclosure.
    </p>

    <p class="text-white">
      4.2 Confidentiality Obligations: You agree to maintain the confidentiality of all Confidential Information and to use it
      only for the purpose of performing your obligations under these Terms. You may disclose Confidential Information only to
      your employees, agents, or contractors who have a need to know and who are bound by confidentiality obligations at least as
      restrictive as those set forth in these Terms.
    </p>

    <p class="text-white">4.3 Exceptions: Your obligations under this Section 4 will not apply to information that:</p>

    <ul>
      <li class="text-white">(a) is or becomes publicly available through no fault of yours;</li>
      <li class="text-white">(b) was lawfully in your possession before receiving it from us;</li>
      <li class="text-white">(c) is rightfully disclosed to you by a third party without restriction on disclosure; or</li>
      <li class="text-white">(d) is independently developed by you without reference to our Confidential Information.</li>
    </ul>

    <h2 class="text-white">Termination</h2>
    <p class="text-white">
      5.1 Termination by You: You may terminate your account at any time by providing us with written notice.
    </p>

    <p class="text-white">
      5.2 Termination by Us: We may terminate your account at any time, with or without cause, by providing you with written
      notice.
    </p>

    <p class="text-white">
      5.3 Effect of Termination: Upon termination of your account, you will no longer have access to the Services, and we may
      delete all of your Content from our servers. Sections 1.4, 2, 3, 4, 5.3, 6, and 7 will survive any termination of these
      Terms.
    </p>

    <p class="text-white">
      5.4 Effect of Termination: All your data will be deleted.
    </p>

    <h2 class="text-white">Disclaimer of Warranties</h2>
    <p class="text-white">
      THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
      WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE MAKE NO
      WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, NOR DO WE MAKE ANY
      WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES.
    </p>

    <h2 class="text-white">Limitation of Liability</h2>
    <p class="text-white">
      IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE
      DAMAGES ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOST PROFITS,
      LOST DATA, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES.
    </p>

    <p class="text-white">
      OUR LIABILITY TO YOU OR ANY THIRD PARTY IN ANY CIRCUMSTANCE WILL NOT EXCEED THE GREATER OF (A) THE AMOUNTS PAID BY YOU TO US
      IN THE TWELVE (12) MONTHS PRECEDING THE CLAIM, OR (B) ONE HUNDRED DOLLARS ($100).
    </p>

    <h2 class="text-white">Governing Law and Dispute Resolution</h2>
    <p class="text-white">
      These Terms will be governed by and construed in accordance with the laws of Estonia, without regard to its conflict of laws
      principles.
    </p>

    <p class="text-white">
      Any dispute arising out of or relating to these Terms or the Services will be resolved by binding arbitration in accordance
      with the Estonian Arbitration Association's rules for commercial arbitration.
    </p>

    <p class="text-white">
      The arbitration will be conducted in Tallinn, Estonia, and the language of the arbitration will be English.
    </p>

    <h2 class="text-white">Miscellaneous</h2>
    <p class="text-white">
      8.1 Entire Agreement: These Terms constitute the entire agreement between you and StopLeads OÜ with respect to the Services
      and supersede all prior or contemporaneous communications and proposals, whether oral or written.
    </p>

    <p class="text-white">
      8.2 Amendments: We may amend these Terms at any time by posting the amended terms on our website. Your continued use of the
      Services after the posting of the amended terms constitutes your agreement to the amended terms.
    </p>

    <p class="text-white">
      8.3 Assignment: You may not assign or transfer these Terms, in whole or in part, without our prior written consent. We may
      assign or transfer these Terms, in whole or in part, without your consent.
    </p>

    <p class="text-white">
      8.4 Waiver: Our failure to enforce any provision of these Terms will not constitute a waiver of that provision.
    </p>

    <p class="text-white">
      8.5 Severability: If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will
      remain in full force and effect.
    </p>

    <p class="text-white">
      8.6 Notices: Any notices required or permitted under these Terms must be in writing and delivered to the address specified
      in these Terms or as otherwise designated by the parties in writing.
    </p>

    <p class="text-white">
      If you have any questions or concerns about these Terms or the Services, please contact us at hello@meetlouis.ai
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.legal {
  h1,
  h2,
  p {
    color: white;
  }
}
</style>
